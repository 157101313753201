import React from 'react'
import { ThemeProvider } from 'theme-ui'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import theme from './theme'

// import pages here 
import Home from './pages/Home'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div style={{ fontFamily: "system-ui, 'avenir next', 'avenir', 'helvetica neue'"}}>
        <Router>
          <Route exact path='/' component={Home} />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App
