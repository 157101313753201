/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, {useState} from 'react'
import theme from '../theme'
import { Delaunay } from 'd3-delaunay' 
import seedrandom from 'seedrandom'
import * as turf from '@turf/turf'
import pointInPolygon from '@turf/boolean-point-in-polygon'

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function Home() {

  const points = []
  

  const [ count, setCount] = useState(256)
  const [ width, setWidth] = useState(960)
  const [ height, setHeight] = useState(600)
  const [ seed, setSeed ] = useState('cloudflare')
  const random = seedrandom(seed)

  for (let i = 0; i < count; ++i) {

    let randomPoint = [ getRandomInt(0, width * random() ), getRandomInt(0, height * random()) ]

    //if (turf.booleanPointInPolygon(randomPoint, poly)) {
      points.push(randomPoint) 
    //}
  }

  const delaunay  = Delaunay.from(points)
  const voronoi  = delaunay.voronoi([0,0, width, height])

  return (
    <div>
<div sx={{ display: 'flex', p: 5 }} >
      <div sx={{p: 3}}>
      <label><span>Points</span> <input value={count} onChange={(event) => setCount(event.target.value)} type='range' min='8' max='4096' steps='1' width={1} /></label>
    </div>
      <div sx={{p: 3}}>
      <label><span>Height</span> <input value={height} onChange={(event) => setHeight(event.target.value)} type='range' min='8' max='1920' steps='1' width={1} /></label>
    </div>
      <div sx={{p: 3}}>
      <label><span>Width</span> <input value={width} onChange={(event) => setWidth(event.target.value)} type='range' min='8' max='2560' steps='1' width={1} /></label>
    </div>
      <div sx={{p: 3}}>
      <label><span sx={{px: 2}}>Seed</span> <input value={seed} onChange={(event) => setSeed(event.target.value)} type='text'width={1} /></label>
    </div>
    </div>
    <div sx={{ display: 'flex', p: 5 }}>
      <svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
        <path stroke='black' strokeWidth='1px' fill='transparent' d={delaunay.render()} />
        <path stroke='black' strokeWidth='2px' fill='transparent' d={delaunay.renderHull()} />
      </svg>
      <svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
        <path stroke='black' strokeWidth='1px' fill='transparent' d={voronoi.render()} />
      </svg>
    </div>
    <div sx={{p: 5}}>
      <svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
        <path stroke={theme.colors.indigo[4]} strokeWidth='1px' fill='transparent' d={delaunay.render()} />
        <path stroke={theme.colors.red[6]} strokeWidth='1px'  fill='transparent' d={voronoi.render()} />
      </svg>
    </div>
    </div>
  );
}

export default Home
